// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e1f7d7;
$primaryMain: #499662; //card
$primaryDark: #499662;
$primary200: rgba(255, 154, 98, 1); //profile
$primary800: #499662;

// secondary
$secondaryLight: #e1f7d7; //this color for icon
$secondaryMain: #499662;
$secondaryDark: #499662;
$secondary200: #499662;
$secondary800: #499662;

// success Colors
$successLight: #b9f6ca;
$success200: #499662;
$successMain: #499662; //sucess color
$successDark: #499662;

// error
$errorLight: #ef9a9a;
$errorMain:rgba(255, 154, 98, 1);
$errorDark:rgba(255, 154, 98, 1);

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: rgba(255, 154, 98, 1);

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark:rgba(255, 154, 98, 1);

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #64ba5f;
$grey500: #499662;
$grey600: #499662;
$grey700: #212121; //side bar text color
$grey900: #212121; //table header color

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #64ba5f; // level 3
$darkPaper: #64ba5f; // level 4

// dark 800 & 900
$darkLevel1: #64ba5f; // level 1
$darkLevel2: #64ba5f; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #64ba5f;
$darkPrimaryDark: #64ba5f;
$darkPrimary200: #64ba5f;
$darkPrimary800: #64ba5f;

// secondary dark
$darkSecondaryLight: #64ba5f;
$darkSecondaryMain: #64ba5f;
$darkSecondaryDark: #64ba5f;
$darkSecondary200: #64ba5f;
$darkSecondary800: #64ba5f;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #64ba5f;
$darkTextSecondary: #64ba5f;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
